module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-426da7c759/0/cache/gatsby-remark-images-npm-7.11.0-1a0bdec2e3-b0b11ad618.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":912,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-bbf4c6ce49/0/cache/gatsby-plugin-manifest-npm-5.11.0-857fd9ff94-cada97619e.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"fromundefined by Tony","start_url":"/","background_color":"#ffffff","icon":"./src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9d106e97be986cdf32cdb934e2454973"},
    },{
      plugin: require('../.yarn/cache/gatsby-plugin-web-font-loader-npm-1.0.4-e6329b179e-7cb4df8ed5.zip/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Pretendard"],"urls":["https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css"]}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-edc79ced63/0/cache/gatsby-plugin-google-gtag-npm-5.11.0-fbf08da52a-5233dbc238.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-08XPH2KV35"],"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-0fe6308d01/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
