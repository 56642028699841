import React, { useEffect } from "react";

import ImgLogo from "../assets/img_logo.png";
import * as styles from "./Logo.css";
import { Gradient } from "whatamesh";

const LOGO_ID = "logo";

const Logo: React.FC = () => {
  useEffect(() => {
    const gradient = new Gradient();
    gradient.initGradient(`#${LOGO_ID}`);
  }, []);

  return (
    <div className={styles.container}>
      <canvas
        id={LOGO_ID}
        data-transition-in
        className={styles.canvas}
        style={
          {
            "--gradient-color-1": "#f4f4f4",
            "--gradient-color-2": "#eeeeee",
            "--gradient-color-3": "#e4e4e4",
            "--gradient-color-4": "#c2c2c2",
          } as React.CSSProperties
        }
      />
    </div>
  );
};

export default Logo;
