import "normalize.css";
import "./src/styles/global.css";
import "./src/styles/prism-fromundefined.css";

import React from "react";
import Root from "./src/Root";

export const wrapPageElement = ({ element }) => {
  return <Root>{element}</Root>;
};
