import { Link } from "gatsby";
import React from "react";

import Logo from "./components/Logo";
import * as styles from "./Root.css";

type RootProps = {
  children?: React.ReactNode;
};
const Root: React.FC<RootProps> = (props) => {
  return (
    <React.Fragment>
      <div className={styles.topContainer}>
        <div className={styles.logoContainer}>
          <Link className={styles.logoLink} to="/">
            <Logo />
          </Link>
        </div>
      </div>
      {props.children}
    </React.Fragment>
  );
};

export default Root;
